import React from "react";
import BlogComponent from "../components/BlogComponent";
function Gallery()
{
  return(
    <div style={{ marginTop: '90px' }}>
    <BlogComponent/>
</div>
  );

}

export default Gallery;