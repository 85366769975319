import React, { useState, useEffect } from "react";
import CustomLightbox from '../CustomLightbox'; // Adjust the import path
import "./index.css";

const BlogComponent = () => {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(-1);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await fetch('https://raw.githubusercontent.com/cyanotracker/support_files_for_website/main/gallery.json',{
          cache: 'no-store' // Removing the Cache Data..
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log("Json data",data);
        
        if (data && Array.isArray(data.gallery)) {
          setImages(data.gallery.map(item => ({
            src: item.image,
            smallCaption: item['smallCaption'],
            bigCaption: item['bigCaption'],
          })));
        } else {
          throw new Error('Invalid JSON structure');
        }
      } catch (error) {
        setError(`Error fetching gallery data: ${error.message}`);
        console.error('Error fetching gallery data:', error);
      }
    };

    fetchGalleryData();
  }, []);

  const handleClose = () => setIndex(-1);
  const handleMoveNext = () => setIndex((index + 1) % images.length);
  const handleMovePrev = () => setIndex((index + images.length - 1) % images.length);

  return (
    <div className="cayno-blog-container">
      <h1 id="blog-heading">CyanoTRACKER Sneak Peeks</h1>
      <div className="blog-gallery">
        {images.map((img, idx) => (
          <div
            key={idx}
            className="blog-item"
            onClick={() => setIndex(idx)}
            data-test-id="grid-gallery-item"
          >
            <div className="image-container">
              <img
                src={img.src}
                alt={img.bigCaption}
                data-testid="grid-gallery-item_thumbnail"
                style={{ cursor: 'pointer' }}
              />
              <div className="small-caption">{img.smallCaption}</div>
            </div>
          </div>
        ))}
      </div>

      {index >= 0 && (
        <CustomLightbox
          image={images[index]}
          onClose={handleClose}
          onMoveNext={handleMoveNext}
          onMovePrev={handleMovePrev}
          caption={images[index].bigCaption}
        />
      )}
    </div>
  );
};

export default BlogComponent;
