import React from "react";
import FormCompnent from "../components/FormComponent";


const Form=()=>{
    return(
        <div style={{ marginTop: '90px' }}>
            <FormCompnent/>
        </div>
    );
}

export default Form;